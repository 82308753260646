import React, { useState } from "react"
import { useOidcUser } from "@axa-fr/react-oidc"
import { useQuery } from "@tanstack/react-query"

import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import { OrderProvider } from "./context/ReturnOrder/Store"
import { MachineProvider } from "./context/Machine/Store"
import Storage from "./utils/storage"
import ClientCompanyModal from "./components/ClientCompanyModal/ClientCompanyModal"
import { RecoilRoot } from "recoil"
import Toaster from "./components/Common/Toast"
import { usePermissionCheck } from "./utils/hooks"

import {
  getUserSettings,
  getInternalCompanies,
  getInternalRegions,
  getDepots,
  getUserAccountGroups,
  getOnBoarding
} from "./utils/hooks"

import OnBoarding from "./components/OnBoarding/OnBoarding"

import {
  useReturnRoute,
  useMachineRoute,
  useOrderRoute,
  useInventoryRoute
} from "./Router/hooks"

const PageWrapper = ({ children }) => {
  const { oidcUser } = useOidcUser()
  const [tenants, setTenants] = useState(null)
  const [showClientCompanyModal, setShowClientCompanyModal] = useState(false)
  const [switchClientAccounts, setSwitchClientAccounts] = useState(false)
  const [userSettingsReady, setUserSettingsReady] = useState(false)
  const [userOnboardingData, setUserOnboardingData] = useState(null)
  const [userOnboardingDataReady, setUserOnboardingDataReady] = useState(false)
  const [customer, setCustomer] = useState(
    Storage.get("customer", null, "local") ?? null
  )

  const hasHLLInternalPermission = usePermissionCheck("MinaSidor.SkapaRetur")
  const hasHLLUserAdminPermissions = usePermissionCheck(
    "Säkerhet.AnvändareGruppAdmin"
  )

  const oid = oidcUser?.tid ?? null
  const role = oidcUser?.role ?? null

  const isReturnRoute = useReturnRoute()
  const isMachineRoute = useMachineRoute()
  const isOrderRoute = useOrderRoute()
  const isInventoryRoute = useInventoryRoute()

  // Fetch internal companies
  useQuery({
    queryKey: ["internalCompanies"],
    queryFn: async () => {
      const response = await getInternalCompanies(false)
      Storage.set("internalCompanies", response, "local")
      return response ?? null
    },
    refetchInterval: 60000 * 60,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    enabled: hasHLLInternalPermission,
    cacheTime: 60000 * 60 // 1 hour
  })

  // Fetch internal regions
  useQuery({
    queryKey: ["internalRegions"],
    queryFn: async () => {
      const response = await getInternalRegions(false)
      Storage.set("internalRegions", response, "local")
      return response ?? null
    },
    refetchInterval: 60000 * 60,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    enabled: hasHLLInternalPermission,
    cacheTime: 60000 * 60 // 1 hour
  })

  // Fetch depots
  useQuery({
    queryKey: ["depots"],
    queryFn: async () => {
      const response = await getDepots()
      Storage.set("depots", response, "local")
      return response ?? null
    },
    refetchInterval: 60000 * 60, // 1 hour
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    cacheTime: 60000 * 60 // 1 hour
  })

  // Fetch user settings
  const { isLoading: userSettingsLoading } = useQuery({
    queryKey: ["userSettings"],
    queryFn: async () => {
      const response = await getUserSettings()
      const { internal } = response?.data ?? null

      // check if user settings are ready
      if(!response?.data || !response?.data?.internal) {
        Storage.remove("userState", "local")
        setUserSettingsReady(false)
        return null;
      }
      
      // check if user settings are ready and depot is set
      if(internal?.depot?.id === null || internal?.depot?.name === null) {
        //console.log("User settings not ready")
        Storage.remove("userState", "local")
        setUserSettingsReady(false)
        return null;
      }
      
      Storage.set("userState", response?.data, "local")
      setUserSettingsReady(true)
      return response ?? null
    },
    refetchOnWindowFocus: true,
    cacheTime: 60000 * 60, // 1 hour
    refetchInterval: 60000 * 60, // 1 hour
    refetchIntervalInBackground: true, 
    refetchOnMount: true
  })

  // Fetch user account groups
  useQuery({
    queryKey: ["userAccountGroups"],
    queryFn: async () => {
      const response = await getUserAccountGroups(oid)
      Storage.set("userGroups", response)
      return response ?? null
    },
    refetchOnWindowFocus: true,
    enabled: hasHLLUserAdminPermissions && !hasHLLInternalPermission,
    cacheTime: 60000 * 60 // 1 hour
  })

  // Fetch onboarding data
  useQuery({
    queryKey: ["onBoarding"],
    queryFn: async () => {
      // make sure to only send the first role
      let userRoleArr = Array.isArray(role) ? [role[0]] : [role]
      const response = await getOnBoarding(userRoleArr)

      setUserOnboardingData(response)
      setUserOnboardingDataReady(true)

      return response ?? null
    },
    refetchOnWindowFocus: false,
    enabled: userSettingsReady && role?.length > 0 && !hasHLLInternalPermission,
    cacheTime: 60000 * 60 // 1 hour
  })

  // Return route
  if (isReturnRoute) {
    return (
      <RecoilRoot>
        <OrderProvider>
          <MachineProvider>
            <div className={`app pb-5`}>
              <Toaster />
              <OnBoarding
                data={userOnboardingData}
                ready={userOnboardingDataReady}
              />
              <Header 
                userSettingsReady={userSettingsReady}
                userSettingsLoading={userSettingsLoading}
              />
              <div className={`app--body pt-4`}>{children}</div>
              <Footer />
            </div>
          </MachineProvider>
        </OrderProvider>
      </RecoilRoot>
    )
  }

  // Machine route
  if (isMachineRoute) {
    return (
      <RecoilRoot>
        <MachineProvider>
          <div className={`app pb-5`}>
            <Toaster />
            <OnBoarding
              data={userOnboardingData}
              ready={userOnboardingDataReady}
            />
            <Header 
              userSettingsReady={userSettingsReady}
              userSettingsLoading={userSettingsLoading}
            />
            <div className={`app--body pt-4`}>{children}</div>
            <Footer />
          </div>
        </MachineProvider>
      </RecoilRoot>
    )
  }

  // Order route
  if (isOrderRoute) {
    return (
      <RecoilRoot>
        <div className={`app pb-5`}>
          <Toaster />
          <OnBoarding
            data={userOnboardingData}
            ready={userOnboardingDataReady}
          />
          <Header
          userSettingsReady={userSettingsReady}
          userSettingsLoading={userSettingsLoading}
          />
          <div className={`app--body pt-4`}>{children}</div>
          <Footer />
        </div>
      </RecoilRoot>
    )
  }

  // Inventory route
  if (isInventoryRoute) {
    return (
      <RecoilRoot>
        <div className={`app pb-5`}>
          <Toaster />
          <OnBoarding
            data={userOnboardingData}
            ready={userOnboardingDataReady}
          />
          <Header
          userSettingsReady={userSettingsReady}
          userSettingsLoading={userSettingsLoading}
          />
          <div className={`app--body pt-4`}>{children}</div>
          <Footer />
        </div>
      </RecoilRoot>
    )
  }

  return (
    <>
      <RecoilRoot>
        <div className={`app pb-5`}>
          <Toaster />
          <OnBoarding
            data={userOnboardingData}
            ready={userOnboardingDataReady}
          />
          <Header
            customer={customer}
            setCustomer={setCustomer}
            showClientCompanyModal={showClientCompanyModal}
            setShowClientCompanyModal={setShowClientCompanyModal}
            setSwitchClientAccounts={setSwitchClientAccounts}
            userSettingsReady={userSettingsReady}
            userSettingsLoading={userSettingsLoading}
          />

          {!hasHLLInternalPermission && (
            <ClientCompanyModal
              tenants={tenants}
              setTenants={setTenants}
              customer={customer}
              setCustomer={setCustomer}
              showClientCompanyModal={showClientCompanyModal}
              setShowClientCompanyModal={setShowClientCompanyModal}
              switchClientAccounts={switchClientAccounts}
              setSwitchClientAccounts={setSwitchClientAccounts}
            />
          )}

          <>
            <main className={`app--body pt-4`}>{children}</main>
            <Footer />
          </>
        </div>
      </RecoilRoot>
    </>
  )
}

export default PageWrapper
