import React from "react"
import { useTranslation } from "react-i18next"
import { useJsApiLoader } from "@react-google-maps/api"

import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete"
import { GOOGLE_MAPS_CONFIG } from "../../../../utils/GoogleMaps/config"
import Loader from "../../../Loader/Loader"

export default function Address({ setAddressData }) {
  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS_CONFIG)

  if (!isLoaded) return <Loader />

  return <Search setAddressData={setAddressData} />
}

const Search = ({ setAddressData }) => {
  const { t } = useTranslation()

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'], // Restrict to addresses only
      locationRestriction: {
        country: ["se"]
      },
    },
    debounce: 300
  })

  const handleInput = (e) => {
    if (e.target.value.length === 0) {
      setAddressData(null)
    }
    setValue(e.target.value)
  }

  const handleClick = async (e) => {
    e.preventDefault()
    const address = e.target.dataset["value"]
    const placeId = e.target.dataset["placeId"]
    setValue(address, false)

    const parameter = {
      placeId: placeId,
      fields: ["name", "address_component"]
    }

    await getDetails(parameter)
      .then((result) => {
        //console.log("Geocoding results: ", result)
        setAddressData(result)
      })
      .catch((error) => {
        console.log("Error: ", error)
      })

    clearSuggestions()
  }

  return (
    <div className="position-relative mb-2">
      <input
        type="search"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={t("Search address")}
        required={true}
        className="form-control rounded-0"
      />
      <ul className="google-maps-suggestions">
        {status === "OK" &&
          data.map(({ place_id, description }) => (
            <li key={place_id} className="google-maps-suggestions--item">
              <a
                href="#!"
                className="nav-link"
                data-value={description}
                data-place-id={place_id}
                onClick={(e) => handleClick(e)}>
                {description}
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}