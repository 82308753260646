import React from "react"
import { useTranslation } from "react-i18next"

import { useJsApiLoader } from "@react-google-maps/api"
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete"
import Loader from "../../Loader/Loader"

import { GOOGLE_MAPS_CONFIG } from "../../../utils/GoogleMaps/config"

const Form = ({
  template,
  handleForm,
  isDisabled,
  setAddressData,
  validation,
  nameRequired,
  numberRequired
}) => {
  const { register, errors, action } = handleForm
  const { fields } = template


  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS_CONFIG)

  if (!isLoaded) return <Loader />

  const renderFields = (fields) => {
    return fields.map((field) => {
      const {
        type,
        title,
        name,
        instructions,
        fieldClassName,
        required,
        scope,
        maxLength
      } = field

      const onChangeValidation = () => {
        validation()
      }

      let requiredCondition = required

      if (name === "name") {
        requiredCondition = nameRequired
      } else if (name === "number") {
        requiredCondition = numberRequired
      }

      let className =
        typeof fieldClassName !== "undefined"
          ? fieldClassName
          : "col-12 col-md-6 mb-3"

      switch (type) {
        case "action":
          return (
            <input
              key={name}
              type="hidden"
              name={name}
              defaultValue={action}
              {...register(name)}
            />
          )

        case "hidden":
          return (
            <input key={name} type="hidden" name={name} {...register(name)} />
          )

        case "text":
        case "email":
          return scope === "googleMaps" ? (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {required && "*"}
              </label>
              <Search setAddressData={setAddressData} required={required} />
            </div>
          ) : (
            <div className={className} key={name}>
              <label
                htmlFor={name}
                className="field__label form-label mt-1 mb-1 text-uppercase">
                {title}
                {requiredCondition && "*"}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                disabled={isDisabled}
                maxLength={maxLength}
                className={`form-control rounded-0${
                  isDisabled ? " disabled" : ""
                }`}
                {...register(name, {
                  requiredCondition,
                  onChange: () => onChangeValidation()
                })}
              />
              {errors[name] && (
                <span className="text-danger d-block font-xs mt-2">
                  {errors[name].message}
                </span>
              )}
              {instructions && (
                <span className="text-muted font-xs mt-2">{instructions}</span>
              )}
            </div>
          )

        default:
          return <div className="col-12">Invalid field</div>
      }
    })
  }

  return <>{renderFields(fields)}</>
}

const Search = ({ setAddressData, required }) => {
  const { t } = useTranslation()

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'], // Restrict to addresses only
      locationRestriction: {
        country: ["se"]
      },
    },
    debounce: 300
  })

  const handleInput = (e) => {
    setValue(e.target.value)
  }

  const handleClick = async (e) => {
    e.preventDefault()
    const address = e.target.dataset["value"]
    const placeId = e.target.dataset["placeId"]
    setValue(address, false)

    const parameter = {
      placeId: placeId,
      fields: ["name", "address_component"]
    }

    await getDetails(parameter)
      .then((result) => {
        //console.log("Geocoding results: ", result)
        setAddressData(result)
      })
      .catch((error) => {
        console.log("Error: ", error)
      })

    clearSuggestions()
  }

  return (
    <div className="position-relative mb-2">
      <input
        type="search"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={t("Search address")}
        required={required}
        className="form-control rounded-0"
      />
      <ul className="google-maps-suggestions">
        {status === "OK" &&
          data.map(({ place_id, description }) => (
            <li key={place_id} className="google-maps-suggestions--item">
              <a
                href="#!"
                className="nav-link"
                data-value={description}
                data-place-id={place_id}
                onClick={(e) => handleClick(e)}>
                {description}
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default Form